import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { ContextProvider } from './context/context';

if (!document.contains(document.getElementById('plugtochat'))) {
    document.createElement('div', { id: 'plugtochat' })
}

ReactDOM.render(
    <ContextProvider>
        <App />
    </ContextProvider>
    , document.getElementById('plugtochat'));