import React, { useState, useEffect, useRef, useContext } from "react";
import { useForm } from 'react-hook-form';
import { FiChevronDown, FiPaperclip, FiPhoneCall, FiPhoneIncoming, FiUser } from 'react-icons/fi';
import { IoSendSharp } from 'react-icons/io5';
import axios from 'axios';
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import { BsEmojiSmile } from 'react-icons/bs';
import { ImPhoneHangUp } from 'react-icons/im'
import './Chat.css';
import { socket } from "../../App";
import { SocketContext } from "../../context/context";
import VideoPlayer from "../VideoPlayer";
import { Carousel } from 'primereact/carousel';
import { Rating } from 'primereact/rating'
import Call from '../Call/Call'

const handleMessages = (data, setData, obj) => {
  if (obj?.wId && data.find(q => q?.message?.id == obj?.wId)) {
    setData(data.map(w => w?.message?.id === obj?.wId ? ({
      ...w,
      ...obj?.type === 'Rating' && { message: { ...w?.message, widget: [obj?.value] } }
    }) : w))
  } else {
    setData([
      ...data,
      obj]
    );
  }
}

const scriptValue = document.querySelector('script');
// const accessToken =  scriptValue.getAttribute('data-ptc-clientId');

let baseAutomation;

const Chat = () => {
  const { register, handleSubmit, formState: { errors }, setError } = useForm();

  const [showChat, setToShowChat] = useState(false);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [showForm, setShowForm] = useState(true);
  const [data, setData] = useState([]);
  const [activeUser, setActiveUser] = useState('');
  const [state, setState] = useState({
    theme: 'dt',
    filterMessage: 'nf',
    showChatHeader: true,
    chatTitleMode: 'dp',
    chatSubTitleMode: 'dcs',
    messageFieldVisibility: 'visible',
    messagePlaceholder: 'Say something...',
    messageSuggestion: '',
    messageFieldAccessPermission: 'raw',
    chatSubject: '',
    welcomeSystemMessage: 'Welcome to the PlugToChat Demo!',
    headerBgColor: 'rgb(42, 39, 218)',
    showEditHeaderBgColor: false,
    showEditHeaderTextColor: false,
    headerTextColor: '#fff',
    welcomeMsgBgColor: 'rgb(192 192 202 / 20%)',
    welcomeMsgTextColor: '#fff',
    showEditWelcomeMsgBgColor: false,
    showEditWelcomeMsgTextColor: false,
    showEditChatBodyBgColor: false,
    showEditFromMessageBgColor: false,
    showEditFromMessageTextColor: false,
    showEditToMessageBgColor: false,
    showEditToMessageTextColor: false,
    chatBodyBgColor: '#fff',
    fromMessageBgColor: 'rgb(42, 39, 218)',
    fromMessageTextColor: '#fff',
    toMessageBgColor: '#ddd',
    toMessageTextColor: '#000',
    sendButtonBgColor: 'rgb(42, 39, 218)',
    sendButtonTextColor: '#fff',
    showEditSendButtonBgColor: false,
    showEditSendButtonTextColor: false,
    inputBgColor: '#fff',
    inputTextColor: '#000',
    showEditInputBgColor: false,
    showEditInputTextColor: false,
  });

  const [inputMessage, setInputMessage] = useState('');
  const [logo, setLogo] = useState(null);

  const [details, setDetails] = useState('');
  const [showEmojis, setShowEmojis] = useState(false);

  const [updateWidget, setUpdateWidget] = useState({})

  const docRef = useRef(null);

  const scriptValue = document.querySelector('script');
  const accessToken = scriptValue.getAttribute('data-ptc-clientId');
  // const accessToken = 'YeBIoUf2Qpla-WZt6Jww';
  const [showCall, setShowCall] = useState(false)
  const [isIncoming, setIsIncoming] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);

  const [automation, setAutomation] = useState(false);
  const [selectedAutomation, setSelectedAutomation] = useState({});

  const save = async (fd) => {
    try {
      const res = await axios.post('https://be.plugtochat.com/user/create-guest', fd);
      socket.emit('user', { id: res.data?._id, userType: 'guest', accessToken });
      setShowForm(false);
    } catch (e) {
      console.log(e)
    }
  }

  const getTheme = async () => {
    try {
      const theme = await axios.get(`https://be.plugtochat.com/user/get-theme-by-clientId/${accessToken}`);
      if (theme) {
        setState({
          ...state,
          ...theme?.data?.editor || {}
        })
        setLogo(theme?.data?.logo)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const addEmoji = (emoji) => {
    if ("native" in emoji) {
      setInputMessage(`${inputMessage}${emoji.native}`);
      setShowEmojis(false);
    }
  };
  const handleDocs = (e) => {
    console.log(e.target.files)
    if (e.target.files) {
      socket.emit('sendMessage', {
        id: details?.id,
        to: details?.to,
        type: 'file',
        file: Array.from(e.target.files)
      });
    }
  }

  const handleUpdateWidget = (p) => {
    socket.emit('updateWidget', {
      id: details?.id,
      to: details?.to,
      wId: p?.id,
      type: p?.type,
      value: p?.value
    })
  }

  useEffect(() => {
    getTheme()
  }, [])

  const dataRef = useRef(data);
  useEffect(() => {
    dataRef.current = data;
  });

  useEffect(() => {
    const handler = (obj) => {
      console.log(obj)
      handleMessages(dataRef.current, setData, obj)
    }
    socket.on('message', handler);
    socket.on('offlineAutomation', (obj) => {
      if (obj?.automation) {
        console.log(obj)
        baseAutomation = obj?.automation ?? {}
        setAutomation(true)
        setData([baseAutomation])
      }
    });
    socket.on('join_call', () => {
      if (!showCall) {
        console.log('fffd')
        setShowCall(true);
        setIsIncoming(true);
      }
    });
    socket.on('groupChat', (obj) => {
      console.log(details)
      setDetails({
        id: obj?.id,
        isGroup: true,
        from: obj?.from
      })
    })
    socket.on('details', (obj) => {
      console.log('details', obj)
      setDetails(obj)
    })
    return () => {
      socket.off('message', handler);
      socket.off('details', (obj) => {
        console.log(obj)
        setDetails(obj)
      })
      socket.off('groupChat', (obj) => {
        setDetails({
          id: obj?.id,
          isGroup: true
        })
      })
    }
  }, [])

  const sendMessage = async (e = null, msg = null) => {
    if (e) {
      e.preventDefault();
    }
    if (msg || inputMessage) {
      try {
        console.log('dhbrd')
        socket.emit('sendMessage', {
          id: details?.id,
          to: details?.to,
          isGroup: details?.isGroup,
          message: msg ?? inputMessage
        });
        setInputMessage('');
      } catch (error) {
        console.log(error)
      }
    }
  }

  const displayAutomation = (val, i) => {
    console.log(val)
    return val?.widget?.map(q => (
      <div className="message-content" style={{
        width: '100%'
      }}>
        <div className="message-text" style={{ background: 'none', width: '100%' }}>
          <div style={{
            width: '100%'
          }}>
            {
              q?.type === 'List Button' && (
                <div style={{
                  padding: '8px 15px',
                  backgroundColor: '#f3f3f3',
                  color: '#444',
                  fontWeight: 500,
                  marginBottom: 12,
                  cursor: 'pointer',
                  borderRadius: 9,
                  width: '80%'
                }} onClick={() => {
                  setData([...data, {
                    fromUser: true,
                    reply: q?.item?.value,
                    itemId: q?.id
                  }, q]);
                }}>
                  {q?.item?.value}
                </div>
              )
            }
            {
              q?.type === 'Rating' && (

                <div className="col-md-8">
                  <h5 style={{ color: '#000' }} className="mb-3">{q?.item?.ratingTitle}</h5>
                  <Rating cancel={false} onChange={(e) => {
                    setSelectedAutomation({
                      ...selectedAutomation,
                      [q?.id]: e?.value
                    })
                    // setUpdateWidget({
                    //   ...updateWidget,
                    //   [i]: {
                    //     ...updateWidget[i],
                    //     id: q?.message?.id,
                    //     type: 'Rating',
                    //     value: { ...p, ratingValue: e.value }
                    //   }
                    // })
                  }} value={selectedAutomation[q?.id] ?? null} />
                  {
                    q?.item.ratingSubmitTitle && (
                      <button className="btn mt-3" style={{
                        backgroundColor: q?.item.ratingButtonBgColor,
                        color: q?.item.ratingButtonTextColor
                      }}>{q?.item?.ratingSubmitTitle}</button>
                    )
                  }
                </div>

              )
            }
            {
              q?.type === 'Feedback' && (
                <div className="col-md-8">
                  <h5 style={{ color: q?.item?.feedbackTitleTextColor }} className="mb-3">{q?.item?.feedbackTitle}</h5>
                  <textarea style={{
                    border: '1px solid #ddd',
                    borderRadius: 12
                  }} className="mb-3"></textarea>
                  {
                    q?.item?.feedbackSubmitTitle && (
                      <button className="btn mt-3" style={{
                        backgroundColor: q?.item?.buttonBgColor,
                        color: '#fff'
                      }}>{q?.item?.feedbackSubmitTitle}</button>
                    )
                  }
                </div>
              )
            }
            {
              q?.type === 'Card' && (
                <div className="col-md-8">
                  <div class="card" style={{
                    background: '#fff',
                    padding: 20,
                    borderRadius: 20,
                    boxShadow: '0px 0px 10px rgb(0 0 0 / 20%)'
                  }}>
                    <div class="card-top" style={{
                      borderTopLeftRadius: 20,
                      borderTopRightRadius: 20,
                      overflow: 'hidden'
                    }}>
                      <img src={q?.item?.cardImg} style={{ width: '100%' }} alt="Card Img Alt" />
                    </div>
                    <div class="card-info">
                      <h2 style={{
                        fontSize: 18.,
                        margin: '10px 0 5px 0',
                        color: q?.item?.cardTitleTextColor
                      }}>{q?.item?.cardTitle}</h2>
                      <span class="date" style={{ marginBottom: 20, fontSize: 14, color: q?.item?.cardSubTitleTextColor, display: 'block' }}>{q?.item?.cardSubtitle}</span>
                      <p class="excerpt" style={{ fontSize: 15, display: 'block', color: q?.item?.cardDescriptionTextColor }}>{q?.item?.cardDesc}</p>
                    </div>
                    <div class="card-bottom row mx-0" style={{
                      marginTop: 12
                    }}>

                      {
                        q?.item?.actions?.map(r => r?.value != '' ? (
                          <div className="col-md-6 px-0 mb-3 me-2">
                            <button className="btn" style={{
                              backgroundColor: r?.buttonBgColor,
                              color: r?.buttonTextColor
                            }} onClick={() => window.open(r?.link)}>{r?.value}</button>
                          </div>
                        ) : (<></>))
                      }

                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    )
    )
  }


  const displayWidgets = (q, i) => {
    return (
      <div className="message-content">
        <div className="message-text" style={{ background: 'none' }}>
          {
            q?.message?.type === 'List Button' && (
              <div className='row'>
                {
                  q?.message?.widget?.map(qp => (
                    <div style={{
                      padding: '8px 15px',
                      backgroundColor: qp?.bgColor,
                      color: qp?.textColor,
                      marginBottom: 12,
                      cursor: 'pointer',
                      borderRadius: 9
                    }} className="col-md-8" onClick={() => {
                      sendMessage(null, qp?.value);
                    }}>
                      {qp?.value}
                    </div>
                  ))
                }
              </div>
            )
          }
          {
            q?.message?.type === 'Radio Button' && (
              <div className='row'>
                {
                  q?.message?.widget?.map(qp => (
                    <div style={{
                      padding: '8px 15px',
                      backgroundColor: '#2787f5',
                      color: qp?.textColor,
                      marginBottom: 12,
                      cursor: 'pointer',
                      borderRadius: 9
                    }} className="col-md-8">
                      <input className='form-check-input' onClick={() => handleUpdateWidget({
                        id: q?.message?.id,
                        type: 'Radio Button',
                        value: qp?.value
                      })} name={q?.message?.id} type={'radio'} /> {qp?.value}
                    </div>
                  ))
                }
              </div>
            )
          }
          {
            q?.message?.type === 'Rating' && (
              <div className='row'>
                {
                  q?.message?.widget?.map(p => (
                    <div className="col-md-8">
                      <h5 style={{ color: p?.ratingTitleTextColor }} className="mb-3">{p?.ratingTitle}</h5>
                      <Rating cancel={false} onChange={(e) => {
                        setUpdateWidget({
                          ...updateWidget,
                          [i]: {
                            ...updateWidget[i],
                            id: q?.message?.id,
                            type: 'Rating',
                            value: { ...p, ratingValue: e.value }
                          }
                        })
                      }} value={p.ratingValue ?? updateWidget[i]?.value?.ratingValue} />
                      {
                        p?.ratingSubmitTitle && (
                          <button className="btn mt-3" style={{
                            backgroundColor: p?.ratingButtonBgColor,
                            color: p?.ratingButtonTextColor
                          }} onClick={() => {
                            if (!p?.ratingValue) {
                              handleUpdateWidget(updateWidget[i])
                            }
                          }}>{p?.ratingSubmitTitle}</button>
                        )
                      }
                    </div>

                  ))
                }
              </div>
            )
          }
          {
            q?.message?.type === 'Feedback' && (
              <div className='row'>
                {
                  q?.message?.widget?.map(p => (
                    <div className="col-md-8">
                      <h5 style={{ color: p?.feedbackTitleTextColor }} className="mb-3">{p?.feedbackTitle}</h5>
                      <textarea className="form-control"></textarea>
                      {
                        p?.feedbackSubmitTitle && (
                          <button className="btn mt-3" style={{
                            backgroundColor: p?.buttonBgColor,
                            color: p?.buttonTextColor
                          }}>{p?.feedbackSubmitTitle}</button>
                        )
                      }
                    </div>

                  ))
                }
              </div>
            )
          }
          {
            q?.message?.type === 'Card' && (
              <div className="row">
                <div className="col-md-8">
                  {
                    q?.message?.widget?.length > 1 ? (<Carousel value={q?.message?.widget} numVisible={3} numScroll={1} responsiveOptions={[
                      {
                        breakpoint: '1024px',
                        numVisible: 3,
                        numScroll: 3
                      },
                      {
                        breakpoint: '600px',
                        numVisible: 2,
                        numScroll: 2
                      },
                      {
                        breakpoint: '480px',
                        numVisible: 1,
                        numScroll: 1
                      }
                    ]} className="custom-carousel"
                      itemTemplate={(d) => (
                        <div class="card" style={{
                          background: '#fff',
                          padding: 20,
                          borderRadius: 20,

                        }}>
                          <div class="card-top" style={{
                            borderTopLeftRadius: 20,
                            borderTopRightRadius: 20,
                            overflow: 'hidden'
                          }}>
                            <img src={d?.cardImg} style={{ width: '100%' }} alt="Card Img Alt" />
                          </div>
                          <div class="card-info">
                            <h2 style={{
                              fontSize: 18.,
                              margin: '10px 0 5px 0',
                              color: d?.cardTitleTextColor
                            }}>{d?.cardTitle}</h2>
                            <span class="date" style={{ marginBottom: 20, fontSize: 14, color: d?.cardSubTitleTextColor, display: 'block' }}>{d?.cardSubtitle}</span>
                            <p class="excerpt" style={{ fontSize: 15, display: 'block', color: d?.cardDescriptionTextColor }}>{d?.cardDesc}</p>
                          </div>
                          <div class="card-bottom row mx-0" style={{
                            marginTop: 12
                          }}>

                            {
                              d?.actions?.map(r => r?.value != '' ? (
                                <div className="col-md-6 px-0 mb-3 me-2">
                                  <button className="btn" style={{
                                    backgroundColor: r?.buttonBgColor,
                                    color: r?.buttonTextColor
                                  }} onClick={() => window.open(r?.link)}>{r?.value}</button>
                                </div>
                              ) : (<></>))
                            }

                          </div>
                        </div>
                      )} />) : (q?.message?.widget?.map(d => (
                        <div class="card" style={{
                          background: '#fff',
                          padding: 20,
                          borderRadius: 20,
                          boxShadow: '0px 0px 10px rgb(0 0 0 / 20%)'
                        }}>
                          <div class="card-top" style={{
                            borderTopLeftRadius: 20,
                            borderTopRightRadius: 20,
                            overflow: 'hidden'
                          }}>
                            <img src={d?.cardImg} style={{ width: '100%' }} alt="Card Img Alt" />
                          </div>
                          <div class="card-info">
                            <h2 style={{
                              fontSize: 18.,
                              margin: '10px 0 5px 0',
                              color: d?.cardTitleTextColor
                            }}>{d?.cardTitle}</h2>
                            <span class="date" style={{ marginBottom: 20, fontSize: 14, color: d?.cardSubTitleTextColor, display: 'block' }}>{d?.cardSubtitle}</span>
                            <p class="excerpt" style={{ fontSize: 15, display: 'block', color: d?.cardDescriptionTextColor }}>{d?.cardDesc}</p>
                          </div>
                          <div class="card-bottom row mx-0" style={{
                            marginTop: 12
                          }}>

                            {
                              d?.actions?.map(r => r?.value != '' ? (
                                <div className="col-md-6 px-0 mb-3 me-2">
                                  <button className="btn" style={{
                                    backgroundColor: r?.buttonBgColor,
                                    color: r?.buttonTextColor
                                  }} onClick={() => window.open(r?.link)}>{r?.value}</button>
                                </div>
                              ) : (<></>))
                            }

                          </div>
                        </div>
                      ))
                    )
                  }

                </div>
              </div>
            )
          }
        </div>
      </div>

    )
  }

  const handleCallEnd = () => {
    setShowCall(false);
  }

  return (
    <div className="outerContainer">
      {
        showChat ? (
          <>
            <div style={{
              position: 'relative',
              margin: 20,
              borderRadius: 15
            }} className="outerContainer-chat">
              <div className="chat-ptc-qe no-clip-path chrome">
                {
                  state?.showChatHeader && (
                    <div className="chat-header-ptc-qe  project-online" style={{
                      color: state?.headerTextColor,
                      background: state?.headerBgColor
                    }}>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'space-between', alignItems: 'center'
                      }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>

                          <div className="avatars-wrapper operators-avatar-4"><div className="header-ava" style={{}}>
                          </div>
                          </div>
                          <div >

                            <h2 className="oneline"><span>
                              {
                                state?.chatTitleMode === 'dp' ? 'Visitor' : state?.chatSubject}
                            </span></h2>
                            {
                              state?.chatSubTitleMode != 'ns' && (
                                <h6 className="oneline"><span>{
                                  state?.chatSubTitleMode === 'dp' ? 'Visitor' : state?.chatSubject}
                                </span></h6>
                              )

                            }
                          </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          {!showForm && !automation && (
                            <p className="exit-chat ripple" style={{ color: state?.headerTextColor, marginRight: 20 }}
                              onClick={() => setShowCall(true)}>
                              <FiPhoneCall style={{ fontSize: '28px', cursor: 'pointer' }} />
                            </p>

                          )}

                          <p className="exit-chat ripple" style={{ color: state?.headerTextColor }} onClick={() => setToShowChat(false)}>
                            <FiChevronDown style={{ fontSize: '28px', cursor: 'pointer' }} />
                          </p>
                        </div>
                      </div>
                      <div className="offline-message" style={{
                        background: state?.welcomeMsgBgColor, color: state?.welcomeMsgTextColor
                      }}><span className={` online ${automation ? 'offline' : ''}`}><span>{state?.welcomeSystemMessage}</span></span></div>
                    </div>)
                }
                <>
                  {
                    showCall ? (
                      <div className='call-dialog'>
                        <Call isAccepted={isAccepted} setIsAccepted={setIsAccepted} activeUser={details} onCallEnd={handleCallEnd} isIncoming={isIncoming} setIsIncoming={setIsIncoming} />
                      </div>
                    ) :
                      showForm ?
                        (
                          <form onSubmit={handleSubmit(save)} className="form-handler" style={{
                            background: '#fff',
                            height: '320px'
                          }}>

                            <div className="form-group">

                              <label className="form-label">
                                Name
                              </label>
                              <input type="text"
                                {...register("name", {
                                  required: 'Required Input'
                                })}
                                className="form-control" placeholder="Name" />
                              {
                                errors['name'] && (
                                  <small className='text-danger mt-3 ms-3'>{errors['name'].message}</small>
                                )
                              }
                            </div>
                            <div className="form-group" style={{ marginTop: '10px' }}>
                              <label className="form-label">
                                Email Address
                              </label>
                              <input type="text"
                                {...register("email", {
                                  required: 'Required Input', pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: 'Invalid Email'
                                  }
                                })}
                                className="form-control" placeholder="name@address.com" />
                              {
                                errors['email'] && (
                                  <small className='text-danger mt-3 ms-3'>{errors['email'].message}</small>
                                )
                              }

                            </div>
                            <button className="btn btn-lg w-100 btn-primary mb-3"
                              style={{ cursor: 'pointer', fontSize: 15, fontWeight: 600 }}>Start</button>
                          </form>
                        ) : (
                          <>

                            <div id="conversation-group-ptc-qe"
                              style={{
                                paddingTop: '20px',
                                background: state?.chatBodyBgColor
                              }} ><div id="-ptc-qe" aria-live="polite" aria-atomic="false" data-testid="messagesLog">

                                <div style={{ float: 'left', clear: 'both', width: '100%' }}></div>
                                {
                                  data.map((d, i) => automation ? !!d?.fromUser ?
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
                                      <div key={i} className={`message-visitor-ptc-qe mb-3`} style={{ backgroundColor: state?.toMessageBgColor, color: state?.toMessageTextColor }}>
                                        <span className="message-content" style={{ color: '#fff' }}>{d?.reply}</span>
                                      </div>

                                    </div> : (
                                      <div style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                                        {displayAutomation(d)}
                                      </div>
                                    ) : d?.from === details?.from ? (
                                      <div style={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }} className="mb-3">
                                        {
                                          d?.type === 'file' ? (
                                            <div className={`message-ptc-qe  message-operator-ptc-qe`} style={{ background: 'none' }}>
                                              {
                                                d?.images.map(qw => (<img src={qw} style={{ width: 200, height: 200 }} />))
                                              }
                                            </div>
                                          ) : d?.type === 'widget' ? (
                                            displayWidgets(d, i)
                                          ) : (

                                            <div key={i} className={`message-ptc-qe  message-operator-ptc-qe`} style={{ backgroundColor: state?.fromMessageBgColor, color: state?.fromMessageTextColor }}>
                                              <span className="message-content" style={{ color: state?.fromMessageTextColor }}>{d?.message}</span>
                                            </div>
                                          )
                                        }
                                      </div>
                                    ) : (<div style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }} className="mb-3">
                                      {
                                        d?.type === 'file' ? (
                                          <div className={`message-visitor-ptc-qe`} style={{ background: 'none' }}>
                                            {
                                              d?.images.map(qw => (<img src={qw} style={{ width: 200, height: 200 }} />))
                                            }
                                          </div>
                                        ) : d?.type === 'widget' ? (
                                          displayWidgets(d, i)
                                        ) : (

                                          <div key={i} className={`message-visitor-ptc-qe mb-3`} style={{ backgroundColor: state?.toMessageBgColor, color: state?.toMessageTextColor }}>
                                            <span className="message-content" style={{ color: state?.toMessageTextColor }}>{d?.message}</span>
                                          </div>
                                        )
                                      }
                                    </div>))
                                }
                              </div>
                              <div id="conversation-scroll">
                                <div style={{ top: '49.7862px', height: '235.307px' }}></div></div></div>
                            {
                              !(automation) && (
                                <div className="input-group  " style={{ background: state?.inputBgColor }}>
                                  <div className="drag-active-wrapper"><hr />
                                    {
                                      state?.messageFieldVisibility === 'visible' && (
                                        <textarea id="new-message-textarea" rows="1" placeholder="Type Message..."
                                          onChange={(e) => setInputMessage(e?.target?.value)}
                                          onKeyPress={(event) => {
                                            if (event.key === 'Enter') {
                                              // setData([...data, {
                                              //     msg: inputMessage,
                                              //     time: Date.now()
                                              // }]);
                                              sendMessage()
                                            }
                                          }}
                                          value={inputMessage} ></textarea>)}

                                  </div>
                                  <div className="footer-bottom" style={{ marginTop: '15px', display: 'flex', alignItems: 'center' }}>
                                    {

                                      state?.theme !== 'dsf' && (
                                        <FiPaperclip onClick={() => docRef.current.click()} style={{ fontSize: '24px', color: 'rgb(136, 148, 171)', cursor: 'pointer' }} />)

                                    }
                                    <a className="text-body pe-0 d-flex align-items-center emoji-section-icon" style={{ position: 'relative', marginLeft: 20 }}>
                                      <BsEmojiSmile style={{ fontSize: 24 }} onClick={() => setShowEmojis(!showEmojis)} />
                                      {
                                        showEmojis && (
                                          <Picker title="Plug To Chat" emoji="" native={true} onSelect={addEmoji} 
                                          emojiSize={26}
                                          style={{ position: 'absolute', bottom: '35px', right: 0, zIndex: 9999 }} />
                                        )
                                      }
                                    </a>
                                    <form>
                                      <input type="file" multiple ref={docRef} onChange={(e) => handleDocs(e)} name="attachment" aria-label="Attach file input" style={{ display: 'none' }} />

                                    </form>
                                    <div></div></div></div>
                              )
                            }
                          </>
                        )
                  }
                </>


              </div>

              {
                !showForm && !showCall && !automation && (
                  <div id="button" className="chat-open mobile-size__medium bubbleAnimation-appear-done bubbleAnimation-enter-done clicked">
                    <div className="buttonWave"></div>
                    <div type="button" id="button-body" style={{
                      background: state?.sendButtonBgColor
                    }} onClick={() => {
                      sendMessage()
                    }}>
                      <IoSendSharp style={{ color: state?.sendButtonTextColor, fontSize: '26px' }} />
                    </div></div>
                )
              }
            </div>
          </>
        ) : (
          <div className="app-icon" onClick={() => setToShowChat(!showChat)}>
            <img src={`https://plugtochat.com/images/favicon.png`} />
          </div>
        )
      }

      {/* {
        showChat ? (
          <div className="container">
             <InfoBar room={state['toName']} closeChat={() => setToShowChat(false)} />
            {
              showForm ?
                (
                  <form onSubmit={handleSubmit(save)} className="form-handler">

                    <div className="form-group">

                      <label className="form-label">
                        Name
                      </label>
                      <input type="text"
                        {...register("name", {
                          required: 'Required Input'
                        })}
                        className="form-control" placeholder="Name" />
                      {
                        errors['name'] && (
                          <small className='text-danger mt-3 ms-3'>{errors['name'].message}</small>
                        )
                      }
                    </div>
                    <div className="form-group" style={{ marginTop: '10px' }}>
                      <label className="form-label">
                        Email Address
                      </label>
                      <input type="text"
                        {...register("email", {
                          required: 'Required Input', pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'Invalid Email'
                          }
                        })}
                        className="form-control" placeholder="name@address.com" />
                      {
                        errors['email'] && (
                          <small className='text-danger mt-3 ms-3'>{errors['email'].message}</small>
                        )
                      }

                    </div>
                    <button className="btn btn-lg w-100 btn-primary mb-3"
                    style={{ cursor: 'pointer' }}>Start</button>
                  </form>
                ) : (
                  <>
                   
                    <Messages messages={messages} name={''} />
                    <Input message={message} setMessage={setMessage} sendMessage={sendMessage} />
                  </>
                )
            }
          </div>
        ) : (
          <div className="app-icon" onClick={() => setToShowChat(!showChat)}>
            <svg width="100%" height="100%" viewBox="0 0 30 33" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M21.333 26.324l6.449 6.376a.752.752 0 001.28-.535V21.44l-7.729 4.884z" fill="#ffffff"></path> <path d="M3.946 0h22.109a3.008 3.008 0 013.008 3.008V21.44l-7.624 5.004H3.945a3.008 3.008 0 01-3.007-3.008V3.008A3.008 3.008 0 013.946 0z" fill="#ffffff"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M21.439 26.444L.937 4.981v21.463H21.44z" fill="url(#gradient)"></path> <defs><linearGradient id="gradient" x1="13.451" y1="12.325" x2="5.559" y2="22.297" gradientUnits="userSpaceOnUse"><stop stop-color="#000000" stop-opacity=".2"></stop> <stop offset="1" stop-color="transparent" stop-opacity="0"></stop></linearGradient></defs></svg>
          </div>
        )
      } */}
    </div>
  );
}

export default Chat;
