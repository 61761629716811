import React, { useCallback, useEffect, useRef, useState } from "react";
import { ImPhone, ImPhoneHangUp } from "react-icons/im";
import { socket } from '../../App'
import "./Call.scss";
import { BsCameraVideo, BsCameraVideoOff, BsFillMicFill, BsFillMicMuteFill } from 'react-icons/bs'

const CallStatus = {
  CALLING: "CALLING",
  ANSWERED: "ANSWERED",
  CONNECTING: "CONNECTING",
}

const pc_config = {
  iceServers: [
    {
      urls: "stun:stun.l.google.com:19302",
    },
  ],
};
let stream;
function Call({ activeUser, onCallEnd, isIncoming, setIsIncoming, setIsAccepted, isAccepted }) {

  const socketRef = useRef(null);
  const pcRef = useRef(null);
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const [muteVideo, setMuteVideo] = useState(true);
  const [muteAudio, setMuteAudio] = useState(true);

  const setVideoTracks = async () => {
    try {
      stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      if (localVideoRef.current) localVideoRef.current.srcObject = stream;
      if (!(pcRef.current && socketRef.current)) return;
      stream.getTracks().forEach((track) => {
        if (!pcRef.current) return;
        pcRef.current.addTrack(track, stream);
      });
      pcRef.current.onicecandidate = (e) => {
        if (e.candidate) {
          if (!socketRef.current) return;
          console.log("onicecandidate");
          socketRef.current.emit("candidate", e.candidate);
        }
      };
      pcRef.current.oniceconnectionstatechange = (e) => {
        console.log(e);
      };
      pcRef.current.ontrack = (ev) => {
        console.log("add remotetrack success");
        if (remoteVideoRef.current) {
          remoteVideoRef.current.srcObject = ev.streams[0];
          const myVideo = stream.getTracks().filter(track => track.kind === "video")[0];
            myVideo.enabled = !myVideo.enabled;
        }
      };
      console.log('drr')
      socketRef.current.emit("join_room", {
        room: activeUser?.id,
        name: activeUser?.name ?? activeUser?.user?.name,
        to: activeUser?.to
      });
    } catch (e) {
      console.error(e);
    }
  };

  const createOffer = async () => {
    console.log("create offer");
    if (!(pcRef.current && socketRef.current)) return;
    try {
      const sdp = await pcRef.current.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      });
      await pcRef.current.setLocalDescription(new RTCSessionDescription(sdp));
      socketRef.current.emit("offer", sdp);
    } catch (e) {
      console.error(e);
    }
  };

  const createAnswer = async (sdp) => {
    if (!(pcRef.current && socketRef.current)) return;
    try {
      await pcRef.current.setRemoteDescription(new RTCSessionDescription(sdp));
      console.log("answer set remote description success");
      const mySdp = await pcRef.current.createAnswer({
        offerToReceiveVideo: true,
        offerToReceiveAudio: true,
      });
      console.log("create answer");
      await pcRef.current.setLocalDescription(new RTCSessionDescription(mySdp));
      socketRef.current.emit("answer", mySdp);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    socketRef.current = socket;
    pcRef.current = new RTCPeerConnection(pc_config);

    socketRef.current.on("getOffer", (sdp) => {
      console.log("get offer");
      createAnswer(sdp);
    });
    socketRef.current.on("user_exit", () => {
      handleCallEnd('user_exit')
    })

    socketRef.current.on("getAnswer", (sdp) => {
      console.log("get answer");
      if (!pcRef.current) return;
      pcRef.current.setRemoteDescription(new RTCSessionDescription(sdp));
    });

    socketRef.current.on(
      "getCandidate",
      async (candidate) => {
        if (!pcRef.current) return;
        await pcRef.current.addIceCandidate(new RTCIceCandidate(candidate));
        console.log("candidate add success");
      }
    );

    setVideoTracks();
    return () => {
      handleCallEnd()
    };
  }, []);

  const handleCallEnd = (type = '') => {
    setIsAccepted(false);
    console.log('dffdfd')
    if (!type) {
      socketRef.current.emit('call_end', {});
    }
    onCallEnd()
    if (stream) {
      stream.getTracks().forEach(function (track) { track.stop(); })
      stream = '';
    }
    if (pcRef.current) {
      pcRef.current.close();
    }
  }

  const pauseTrack = (type) => {
    if (stream) {
      const myAudio = stream.getTracks().filter(track => track.kind === "audio")[0];
      const myVideo = stream.getTracks().filter(track => track.kind === "video")[0];
      if (type == 'video') {
        myVideo.enabled = !myVideo.enabled;
        setMuteVideo(!muteVideo);
      }
      if (type == 'audio') {
        myAudio.enabled = !myAudio.enabled
        setMuteAudio(!muteAudio);
      }

    } else {
      handleCallEnd();
    }
  }


  const handleAnswer = () => {
    setIsAccepted(true)
    setIsIncoming(false)
    createOffer();
  }

  return (
    <main className="call">
      <div className="call--background">
        {(!muteVideo || isIncoming) ? (
          <div className="call--background--contact">
            <div className="call--background--contact--image">
              <div className={`avatar`}>
                <span className={`avatar-text`} style={{ background: '#2787f5', color: '#fff' }}>{activeUser?.user?.name?.slice(0, 1) || 'S'}</span>

              </div>
            </div>
            <span className="call--background--contact--name" style={{ color: '#fff', fontSize: 16 }}>
              Agent calling....
            </span>
          </div>
        ) : (
          <video
            ref={remoteVideoRef}
            className="call--background--media"
            autoPlay
            playsInline
          />
        )}
      </div>
      <div className="call--overlay">
        <header className="call--overlay--header">
          <span className="call--overlay--header--status">
            {/* {callStatus === CallStatus.CALLING
                        ? "Calling..."
                        : callStatus === CallStatus.CONNECTING
                            ? "Connecting..."
                            : callTimer} */}
          </span>
        </header>
        <div />
        <footer className="call--overlay--footer">
          <section className="call--overlay--footer--yourself">
            <video
              ref={localVideoRef}
              className="call--overlay--footer--yourself--media"
              autoPlay
              playsInline
              controls
              muted={true}
            />
          </section>
          <section className="call--overlay--footer--actions">
            {(isIncoming && !isAccepted) && (
              <button
                className="call--overlay--footer--button call--overlay--footer--button--hang-up"
                style={{ background: 'green', color: '#fff' }}
                onClick={() => handleAnswer()}
              >
                <ImPhone color="#fff" size={24} />
              </button>
            )}
            {
              !isIncoming && isAccepted && (<>
                <button
                  className="call--overlay--footer--button call--overlay--footer--button--hang-up"
                  onClick={() => pauseTrack('audio')}
                  style={{ background: '#666' }}
                >
                  {
                    muteAudio ? (
                      <BsFillMicFill color="#fff" size={24} />

                    ) : (
                      <BsFillMicMuteFill color="#fff" size={24} />
                    )
                  }
                </button>
                <button
                  className="call--overlay--footer--button call--overlay--footer--button--hang-up"
                  onClick={() => pauseTrack('video')}
                  style={{ background: '#666' }}
                >
                  {
                    muteVideo ? (
                      <BsCameraVideo color="#fff" size={24} />

                    ) : (
                      <BsCameraVideoOff color="#fff" size={24} />
                    )
                  }
                </button>
              </>)
            }
            <button
              className="call--overlay--footer--button call--overlay--footer--button--hang-up"
              onClick={() => handleCallEnd()}
            >
              <ImPhoneHangUp color="#fff" size={20} />
            </button>
          </section>
        </footer>
      </div>
    </main>
  );
}

export default Call;