import React from 'react';
import Chat from './components/Chat/Chat';
import useSocket from './Hooks/useSocket';
import './_App.scss';
export let socket;

function App() {
  [socket] = useSocket("https://be.plugtochat.com");
  return (
    <Chat />
  );
}

export default App;
